<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Informes de Cobranzas</h4>
            <div class="small text-muted">Administración de todos los informes de cobranzas</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Informe">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="3">
            <b-row>
              <b-col md="6" class="pr-0">
                <b-form-input type="date" size="md" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="6" class="pl-0">
                <b-form-input type="date" size="md" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="6">
            <b-row>
              <b-col sm="3">
                <v-select :options="arr.filter.staff" v-model="filter.staff" placeholder="Staff" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>        
              <b-col sm="3">
                <v-select :options="arr.filter.sellers" v-model="filter.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>       
              <b-col sm="3">
                <v-select :options="arr.filter.customers" v-model="filter.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>       
              <b-col sm="3">
                <v-select :options="arr.filter.read" v-model="filter.read" placeholder="Leido" :multiple="false" :select-on-tab="true"></v-select>                
              </b-col>               
            </b-row>
          </b-col>      
          <b-col sm="3">
            <b-row>
              <b-col sm="6">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
              <b-col sm="6">
                <b-link>
                  <export-excel
                    class = 'pull-right mt-3'
                    :data = "arr.export"
                    worksheet = "Informe de Cobranzas"
                    name = "reports-payment.xls">
                    Exportar Datos
                  </export-excel>            
                </b-link>
              </b-col>    
            </b-row>
          </b-col>                  
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="formartedItems"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>

                <template v-slot:cell(date)="data">
                  {{ moment(data.item.date).format('DD MMMM YYYY') }}
                </template>

                <template v-slot:cell(responsable)="data">
                  <div v-if="data.item.staff_id">
                    <b-avatar :src="data.item.staff.image"
                              v-if="data.item.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>                         
                    <b-icon icon="blank"></b-icon>
                    <b :title="'STAFF: ' + data.item.staff.name">
                      {{data.item.staff.name}}
                    </b>                                          
                  </div>
                  <div v-else>                    
                    <b-avatar :src="data.item.seller.staff.image"
                              v-if="data.item.seller.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>     
                    <b-icon icon="blank"></b-icon>
                    <b :title="'VENDEDOR: ' + data.item.seller.staff.name">
                      {{data.item.seller.staff.name}}
                    </b>  
                  </div>                  
                </template>

                <template v-slot:cell(customers)="data">     
                  <div v-if="data.item.customers_id">             
                    <b-avatar :src="data.item.customer.image"
                              v-if="data.item.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>     
                    &nbsp;<b>{{data.item.customer.name}}</b>      
                    <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                           
                  </div>
                </template>

                <template v-slot:cell(read)="data">
                  <b-icon icon="toggle-on"  @click="checkRead(data.item)" class="h4 report-payment-check-read-table" v-if="!data.item.read" v-b-tooltip.hover title="Sin Leer"></b-icon>
                  <b-icon icon="toggle-off" class="h4" v-else v-b-tooltip.hover title="Leido"></b-icon>
                </template>

                <template v-slot:cell(status)="row">
                  <div class="text-center" v-if="row.item.surrender_detail">
                    <b-badge v-if="row.item.surrender_detail.status=='Enviado'" variant="info" class="report-payment-surrender-badge">
                      {{row.item.surrender_detail.status}}
                    </b-badge>

                    <b-badge v-if="row.item.surrender_detail.status=='En Revisión'" variant="warning" class="report-payment-surrender-badge">
                      {{row.item.surrender_detail.status}}
                    </b-badge>

                    <b-badge v-if="row.item.surrender_detail.status=='Aprobado'" variant="success" class="report-payment-surrender-badge">
                      {{row.item.surrender_detail.status}}
                    </b-badge>
                    
                    <b-badge v-if="row.item.surrender_detail.status=='Rechazado'" variant="danger" class="report-payment-surrender-badge">
                      {{row.item.surrender_detail.status}}
                    </b-badge>

                    <b-badge v-if="row.item.surrender_detail.status=='No Recibido'" variant="secondary" class="report-payment-surrender-badge">
                      {{row.item.surrender_detail.status}}
                    </b-badge>
                  </div>
                </template>                

                <template v-slot:cell(amounts_items)="data">
                  <b>$ {{ data.item.amounts_items }}</b>
                </template>                

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      {{data.item.observations.substr(0, 50)}}
                    </div>
                    <div v-else>
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">

                    <b-dropdown-item @click="irDetail(data.item)">
                      <b-icon icon="card-text"></b-icon> Detalle
                    </b-dropdown-item>       

                    <b-dropdown-item @click="checkRead(data.item)" v-if="!data.item.read">
                      <b-icon icon="eye-fill"></b-icon> Marcar como leido
                    </b-dropdown-item>       
                    <b-dropdown-item @click="checkNotRead(data.item)" v-if="data.item.read">
                      <b-icon icon="eye-slash-fill"></b-icon> Marcar como no leido
                    </b-dropdown-item>                           

                    <b-dropdown-item @click="sendPrinterOrder(data.item)">
                      <b-icon icon="printer"></b-icon> Imprimir
                    </b-dropdown-item>    

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteReport">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>            
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterReport()" />
            </nav>    
          </b-col>          
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop              
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>      
          <b-col md="6">
            <b-form-group label="Fecha">
              <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>

          <b-col md="6">
            <FindObject render="search"
                        type="customers" 
                        @select-object="loadCustomers($event)" 
                        :valueID="crud.form.customers_id"/>                 
          </b-col>    
        </b-row>
        
        <form-wizard v-if="crud.form.customers_id"
                      title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save">

          <tab-content title="Comprobantes" icon="icon-docs">          
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-card>  
                  <div slot="header" class="p-0">
                    <i class="icon-docs"></i><strong> Que cancela</strong>
                    <span class="balance-pending">
                      <b>Saldo Pendiente: </b> <span style="color: red;">$ {{this.total.vouchersBalancePending}}</span>
                    </span>                  
                  </div>

                  <b-row>
                    <b-col lg="2" md="4">            
                      <b-form-group label="Tipo">                    
                        <b-form-select v-model="crud.form.type_operation" :options="arr.select.typeVouchers" @change="getVouchersPendingByCustomers($event)"></b-form-select>                    
                      </b-form-group>
                    </b-col>
                    <b-col lg="8" md="4">            
                      <b-form-group label="Comprobantes Pendientes">
                        <b-form-select v-model="crud.form.vouchers_tmp.vouchers_id" :options="arr.select.vouchersPending"></b-form-select>
                      </b-form-group>
                    </b-col>              
                    <b-col lg="2" md="4">
                      <b-button class="report-payment-button-add" @click="addVouchers()">
                        <i class="fa fa-plus"></i>
                      </b-button>                  
                    </b-col>
                  </b-row>            
                  
                  <b-row>
                    <b-col>                  
                      <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="crud.form.vouchers"
                        :fields="table_vouchers">
                        
                        <template v-slot:cell(date)="data">
                          <div>{{ data.item.date }}</div>
                        </template>
                        
                        <template v-slot:cell(vouchers)="data">
                          <div>{{ data.item.vouchers }}</div>
                        </template>

                        <template v-slot:cell(amount)="data">
                          <div>$ {{ data.item.amount }}</div>
                        </template>

                        <template v-slot:cell(f_action)="data">                    
                          <b-dropdown right text="Acción" size="sm" variant="outline-dark">                      
                            <b-dropdown-item @click="editVouchers(data.item, data.index)">
                              <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteVouchers(data.index)" style="color:red;">
                              <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                            </b-dropdown-item>     
                          </b-dropdown>                     
                        </template>

                      </b-table>                  
                    </b-col>
                  </b-row>

                  <hr>
                  
                  <b-row>
                    <b-col>
                      <div class="report-payment-label report-payment-total-size">
                        TOTAL: $ {{ totalVouchers }}
                      </div>            
                    </b-col>
                  </b-row>
                </b-card>          
              </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Pago" icon="fa fa-money">          
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-card>  
                  <div slot="header">
                    <i class="fa fa-money"></i><strong> Desglose de Pago</strong>
                  </div>

                  <b-row>
                    <b-col lg="4" md="4">            
                      <b-form-group label="Medio">                  
                        <b-form-select v-model="crud.form.detail_register.type" :options="arr.select.type"></b-form-select>                    
                      </b-form-group>
                    </b-col>                     
                    <b-col lg="5" md="5">
                      <b-form-group label="Importe"> 
                        <b-input-group prepend="$">
                          <input v-model="crud.form.detail_register.amount" type="number" min="0" step="0.01" class="form-control"/>                      
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col lg="3" md="3">
                      <b-button class="report-payment-button-add" @click="addItem(false)" v-b-tooltip.hover title="Agregar Item">
                        <i class="fa fa-plus"></i>
                      </b-button>                              
                      <b-button class="report-payment-button-check" @click="openCheck()" v-if="crud.form.detail_register.type == 'cheque'" v-b-tooltip.hover title="Ver datos del cheque">
                        <i class="fa fa-credit-card-alt"></i>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>                  
                      <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="crud.form.detail"
                        :fields="table_payment">
                                                                
                        <template v-slot:cell(type)="data">
                          <div>{{ data.item.type.toUpperCase() }}</div>
                        </template>
                        
                        <template v-slot:cell(check)="data">  
                          <div v-if="data.item.type=='cheque'">
                            <div :id="'popover-target-' + data.index" class="badge badge-secondary text-wrap report-payment-popover-size">
                              Más Info Cheque
                            </div>
                            <b-popover :target="'popover-target-' + data.index" title="Info Cheque" triggers="hover" placement="top">                      
                              <table>
                                <tr>
                                  <td class="report-payment-popover-item-header">Tipo</td>
                                  <td>{{data.item.check.type.toUpperCase()}}</td>
                                </tr>                            
                                <tr>
                                  <td class="report-payment-popover-item-header">Banco</td>
                                  <td>{{data.item.check.bank}}</td>
                                </tr>
                                <tr>
                                  <td class="report-payment-popover-item-header">Número</td>
                                  <td>{{data.item.check.number}}</td>
                                </tr> 
                                <tr>
                                  <td class="report-payment-popover-item-header">Fecha</td>
                                  <td>
                                    <div v-if="data.item.check.date_movement">
                                      {{moment(data.item.check.date_movement).format('DD/MM/YYYY')}}
                                    </div>
                                  </td>
                                </tr>                        
                                <tr>
                                  <td class="report-payment-popover-item-header">CUIT</td>
                                  <td>{{data.item.check.cuit}}</td>
                                </tr>
                                <tr>
                                  <td class="report-payment-popover-item-header">Observaciones</td>
                                  <td>{{data.item.check.observations}}</td>
                                </tr>                                                                            
                              </table>
                            </b-popover>                    
                          </div>
                        </template>
                        
                        <template v-slot:cell(amount)="data">  
                          <div class="report-payment-text-red" v-if="data.item.type=='nota de débito'">
                            -$ {{ parseFloat(data.item.amount).toFixed(2) }}
                          </div>
                          <div class="report-payment-text-green" v-else>
                            $ {{ parseFloat(data.item.amount).toFixed(2) }}
                          </div>
                        </template>                  
                                                                
                        <template v-slot:cell(f_action)="data">  
                          <b-dropdown right text="Acción" size="sm" variant="outline-dark">                      
                            <b-dropdown-item @click="editItem(data.item, data.index)">
                              <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteItem(data.index)" style="color:red;">
                              <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                            </b-dropdown-item>     
                          </b-dropdown>                                
                        </template>                    
                      </b-table>                  
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col>
                      <div class="report-payment-label report-payment-total-size">
                        TOTAL: $ {{ totalPayment }}
                      </div>   
                    </b-col>
                    <b-col>                  
                      <div class="report-payment-label report-payment-total-size report-payment-diff-lack" v-if="diffPayment<0">
                        FALTAN: $ {{ diffPayment }}
                      </div> 
                      <div class="report-payment-label report-payment-total-size report-payment-diff-surplus" v-if="diffPayment>0">
                        SOBRAN: $ {{ diffPayment }}
                      </div> 
                      <div v-if="totalPayment>0">
                        <div class="report-payment-label report-payment-total-size report-payment-diff-just" v-if="diffPayment==0">
                          JUSTO
                        </div>
                      </div>               
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>        
            </b-row>
          </tab-content>
          <tab-content title="Observaciones" icon="fa fa-flag-checkered">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>              
        </div>
      </b-modal>
      
      <!-- CRUD CHEQUE -->      
      <b-modal v-model="modal.check.active"
              centered
              header-bg-variant="dark"
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop               
              size="md">
        <div slot="modal-header">
          {{this.modal.check.title}}
        </div>

        <b-row>
          <b-col md="4">            
            <b-form-group label="Tipo">
              <b-form-select v-model="crud.form.detail_register.check.type" :options="arr.select.check"></b-form-select>
            </b-form-group>
          </b-col>            
          <b-col md="4">
            <b-form-group label="Banco">            
              <b-form-input v-model="crud.form.detail_register.check.bank" type="text" size="md"></b-form-input>            
            </b-form-group>
          </b-col>       
          <b-col md="4">
            <b-form-group label="Número Cheque">            
              <b-form-input v-model="crud.form.detail_register.check.number" type="text" size="md"></b-form-input>            
            </b-form-group>
          </b-col> 
          <b-col md="6">
            <b-form-group label="Fecha Movimiento">            
              <b-form-input v-model="crud.form.detail_register.check.date_movement" type="date" size="md"></b-form-input>            
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="CUIT">            
              <b-form-input v-model="crud.form.detail_register.check.cuit" type="text" size="md"></b-form-input>                 
              <a class="verify-credit-status" @click="verifyBCRA()">
                    Verifique la situación crediticia antes de continuar.
                  <i class="cui-chevron-right"></i><i class="cui-chevron-right"></i>
              </a>              
            </b-form-group>
          </b-col>      
          <b-col md="12">
            <b-form-group label="Observaciones (opcional)">            
              <b-form-input v-model="crud.form.detail_register.check.observations" type="text" size="md"></b-form-input>            
            </b-form-group>
          </b-col>                         
        </b-row>                 

        <div slot="modal-footer">
          <b-button size="sm" variant="outline-secondary" class="mr-1" @click="modal.check.active=false">Cancelar</b-button>
          <b-button size="sm" variant="secondary" @click="addItem(true)">Guardar</b-button>
        </div>
      </b-modal>

      <!-- CRUD VOUCHER AMOUNT -->    
      <b-modal v-model="modal.vouchers.active"
              centered
              header-bg-variant="dark"
              header-text-variant="white"              
              no-close-on-esc
              no-close-on-backdrop               
              size="sm">
        <div slot="modal-header">
          {{this.modal.vouchers.title}}
        </div>
        
        <b-row>
          <b-col md="12">
            <b-form-group label="Fecha">            
              <b-form-input v-model="crud.form.vouchers_edit.date" type="text" readonly size="sm"></b-form-input>            
            </b-form-group>
          </b-col>  

          <b-col md="12">
            <b-form-group label="Comprobante">            
              <b-form-input v-model="crud.form.vouchers_edit.vouchers" type="text" readonly size="sm"></b-form-input>            
            </b-form-group>
          </b-col>  

          <b-col md="12">
            <b-form-group label="Importe">
              <b-input-group prepend="$" size="md">            
                <b-form-input v-model="crud.form.vouchers_edit.amount" type="number" setp="0.01" min="0.00"></b-form-input>            
              </b-input-group>
            </b-form-group>
          </b-col>  
        </b-row>        

        <div slot="modal-footer">          
          <b-button size="sm" variant="outline-secondary" class="mr-1" @click="modal.vouchers.active=false">Cancelar</b-button>
          <b-button size="sm" class="secondary" @click="vouchersEditSave(crud.form.vouchers_edit)">Guardar</b-button>          
        </div>
      </b-modal>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Funciones from '@/handler/funciones' 
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject' 
  import Vue from 'vue'
  import excel from 'vue-excel-export'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Storage from '@/handler/storageSession'
  import Printer from '@/components/inc/printer/printer'

  Vue.use(excel)

  export default {
    components: {
      FindObject,
      FormWizard,
      TabContent,
      Printer     
    }, 
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_COBRANZAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudReport',
          elements: {            
            deleteReport: true             
          }
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: "align-middle"},
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'responsable', label: 'Responsable', class: "align-middle"},
            {key: 'customers', label: 'Cliente', class: "align-middle"},            
            {key: 'amounts_items', label: 'Total', class: "align-middle text-center"},
            {key: 'observations', label: 'Observaciones', class: "align-middle"},
            {key: 'status', label: 'Rendición', class: 'align-middle text-center'},
            {key: 'read', label: 'Estado', class: "align-middle text-center"},            
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,      
        },
        table_vouchers : [
          {key: 'date', label: 'Fecha'},    
          {key: 'vouchers', label: 'Comprobante'},    
          {key: 'amount', label: 'Importe'},   
          {key: 'f_action', label:'Acción'}, 
        ],
        table_payment : [
          {key: 'type', label: 'Medio'},              
          {key: 'check', label: 'Info Extra'},  
          {key: 'amount', label: 'Importe'},                
          {key: 'f_action', label:'Acción'},
        ],
        crud: {
          form: {
            id: 0,
            date: '',
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,
            read: false,

            type_operation: '',
            vouchers_pending: [],
            vouchers_edit: {},                       
            vouchers_tmp: {            
              vouchers_id: null,            
            },                
            vouchers: [],

            detail_register: {
              type: 'efectivo',
              amount_items: 0,              
              amount_vouchers: 0,              
              check: {              
                type: 'de_terceros',                
                bank: '',                  
                number: '',
                date_movement: '',
                cuit: '',
                observations: ''
              },    
            },
            detail: []                 
          },    
          print: {
            reference: 'reports-payment',
            id: 0,
            key: 0,
          }       
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          vouchers: {
            active: false,
            title: ''            
          },          
          check: {
            active: false,
            title: ''
          },                    
        },
        arr: {
          export: [],
          filter : {
            customers: [],
            staff: [],
            sellers: [],
            read: [
              {code:true, label: 'Leido'},
              {code:false, label: 'No Leidos'},                            
            ],            
          },
          select: {
            type: [
              { value: 'efectivo', text: 'EFECTIVO (+)' },
              { value: 'cheque', text: 'CHEQUE  (+)' },
              { value: 'transferencia', text: 'TRANSFERENCIA  (+)' },
              { value: 'ret iibb', text: 'RET. IIBB  (+)' },
              { value: 'ret ganancia', text: 'RET. GANANCIA  (+)' },
              { value: 'ret iva', text: 'RET. IVA  (+)' },
              { value: 'ret suss', text: 'RET. SUSS  (+)' },
              { value: 'nota de débito', text: 'NOTA DE DEBITO  (-)' },
              { value: 'descuentos', text: 'DESCUENTOS  (+)' },
              { value: 'nota de crédito', text: 'NOTA DE CREDITO  (+)' },          
              { value: 'anticipos', text: 'ANTICIPOS  (+)' },          
            ],
            check: [
              { value: 'propios', text: 'PROPIOS' },
              { value: 'de_terceros', text: 'DE TERCEROS' },                            
            ],
            typeVouchers: [
              { value: 'BLANCO', text: 'D' },
              { value: 'NEGRO', text: 'ND' },                            
            ],
            vouchersPending: []        
          }
        },
        filter: {
          date_start: '',
          date_end: '',
          customers: [],
          staff: [],
          sellers: [],
          read: null,
        },
        total : {
          vouchersBalancePending: 0,
          vouchersBalancePendingValid: 0,
        },    
        customersID: 0,   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.deleteReport = Helper.hasAccessElement(this.access, 'delete_report')       
      /* Fin configuracion */
    },  
    mounted() {      
      this.customersID = this.$route.params.customersID
      if(this.customersID) {
        this.validCustomers()
      }

      if(this.isSeller) {
        this.$router.push({ name: 'ReportsPaymentStaffCrudReportSeller' })
      }

      this.filterLoad()
      this.filterReport()
    },
    computed: {
      formartedItems () {
        if (!this.table.items) return []
        return this.table.items.map(item => {
          if(!item.read){
            item._rowVariant  = 'active'
          }
          return item
        })
      },
      totalVouchers () {        
        return this.crud.form.vouchers.reduce((sum, item) => sum + parseFloat(item.amount), 0).toFixed(2)
      },
      totalPayment () {
        return this.crud.form.detail.reduce((sum, item) => {
          if (item.type=='nota de débito'){
            return sum - parseFloat(item.amount)  
          } else {
            return sum + parseFloat(item.amount)  
          }            
        }, 0).toFixed(2)
      },      
      diffPayment () {
        return (this.totalPayment - this.totalVouchers).toFixed(2)
      }, 
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      }                          
    },    
    methods: {
      validCustomers() {        
        this.add()                
        this.crud.form.customers_id = parseInt(this.customersID.slice(0, this.customersID.indexOf('|')))
      },
            
      // SALDOS Y COMPROBANTES PENDIENTES DE PROVEEDORES
      getVouchersPendingByCustomers(value) {      
        this.arr.select.vouchersPending = []

        if(value=='BLANCO') {
          this.getVouchersPendingByCustomers_B()    
        }

        if(value=='NEGRO') {
          this.getVouchersPendingByCustomers_N()    
        }    

        this.crud.form.vouchers = []         
      },
      getVouchersPendingByCustomers_B () {        
        let loader = this.$loading.show();
        var result = serviceAPI.getVouchersPendingByCustomers({
          type: 'BLANCO',
          customers_code: this.crud.form.customers.code
        })  
                
        result.then((response) => {                      
          var vouchersPending = response.data
          this.crud.form.vouchers_pending = vouchersPending
          
          var selectValue = []
          selectValue.push({
            value : null,
            text : 'Seleccionar Comprobantes',
          })
          vouchersPending.forEach((value, key) => {
            selectValue.push({
              value : value.id,
              text : value.vouchers + ' | ' + value.date + ' | $' + parseFloat(value.amount),
            })
          });
                    
          this.arr.select.vouchersPending = selectValue          

          loader.hide()          
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        });        
      },
      getVouchersPendingByCustomers_N () {        
        let loader = this.$loading.show();
        var result = serviceAPI.getVouchersPendingByCustomers({
          type: 'NEGRO',
          customers_code: this.crud.form.customers.code
        })  

        result.then((response) => {     
          var vouchersPending = response.data
          this.crud.form.vouchers_pending = vouchersPending
          
          var selectValue = []
          selectValue.push({
            value : null,
            text : 'Seleccionar Comprobantes',
          })
          vouchersPending.forEach((value, key) => {
            selectValue.push({
              value : value.id,
              text : value.vouchers + ' | ' + value.date + ' | $' + parseFloat(value.amount),
            })
          });
                  
          this.arr.select.vouchersPending = selectValue          

          loader.hide()          
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        });        
      },
      getBalancePendingByCustomers(value) {              
        if(value) {
          var result = serviceAPI.getBalancePendingByCustomers({
            customers_code: value.code
          })        

          result.then((response) => {          
            var data = response.data;          
            if(data.amount) {
              this.total.vouchersBalancePending = data.amount
            } else {
              this.total.vouchersBalancePending = 0
            }
          })
          .catch(error => {
            this.$awn.alert(Error.showError(error));    
          });      
        } else {
          this.total.vouchersBalancePending = 0        
        }
      },

      // ABM VOUCHERS
      addVouchers() {                
        if (!this.crud.form.vouchers_tmp.vouchers_id) {
          this.$awn.alert('Falta seleccionar el comprobante')                    
          return false
        }

        var exists = Funciones.selectArrayItemByField(this.crud.form.vouchers, this.crud.form.vouchers_tmp.vouchers_id, 'vouchers_id')        
        if(exists!=null) {
          this.$awn.alert('El comprobante ya se agregó')          
          return false
        }

        var vouchersPendingSelect = Funciones.selectArrayItemByField(this.crud.form.vouchers_pending, this.crud.form.vouchers_tmp.vouchers_id, 'id')
        
        var arrItem = { vouchers_id: vouchersPendingSelect.id,
                        date: vouchersPendingSelect.date, 
                        vouchers: vouchersPendingSelect.vouchers, 
                        amount: vouchersPendingSelect.amount}

        this.crud.form.vouchers.push(arrItem)        
        this.crud.form.vouchers_tmp.vouchers_id = null  
      },
      deleteVouchers (index) {
        this.crud.form.vouchers.splice(index, 1)
      },      
      editVouchers (row, index) {
        this.modal.vouchers.active = true
        this.modal.vouchers.title = 'Cambiar Importe Comprobante'
        
        this.crud.form.vouchers_edit = row        
      },        
      vouchersEditSave (row) {        
        if(row.amount) {
          if(parseFloat(this.total.vouchersBalancePendingValid) < parseFloat(row.amount)) {
            this.$awn.alert("El importe no puede ser mayor al importe del comprobante");     
            row.amount = this.total.vouchersBalancePendingValid
          }  
        } else {
          row.amount = this.total.vouchersBalancePendingValid
        }
        
        this.modal.vouchers.active = false        
      },      

      // ABM ITEMS
      addItem (status_check) {
        if (!this.crud.form.detail_register.type) {
          this.$awn.alert('Falta seleccionar el medio de pago')          
          return false
        }

        if (!this.crud.form.detail_register.amount) {
          this.$awn.alert('Falta cargar el importe del pago')             
          return false
        }          

        if (this.crud.form.detail_register.type == 'cheque' && !this.checkOk()) {
          this.modal.check.active = true
          this.modal.check.title = "Datos del cheque"
          this.crud.form.detail_register.check.type = 'de_terceros'
          if (status_check) {
            this.$awn.alert('Falta cargar datos del cheque')
          }
          return false
        } 

        var arrItem = { type: this.crud.form.detail_register.type, 
                        amount: this.crud.form.detail_register.amount,                         
                        check: {
                          type: this.crud.form.detail_register.check.type,              
                          bank: this.crud.form.detail_register.check.bank,
                          number: this.crud.form.detail_register.check.number,
                          cuit: this.crud.form.detail_register.check.cuit,                          
                          date_movement: this.crud.form.detail_register.check.date_movement,
                          observations: this.crud.form.detail_register.check.observations
                        }
                      }

        this.crud.form.detail.push(arrItem)
        
        this.crud.form.detail_register.type = 'efectivo'
        this.crud.form.detail_register.amount = 0        
        this.modal.check.active = false
        this.clearCheck()        
      },      
      deleteItem (index) {
        this.crud.form.detail.splice(index, 1)
      },
      editItem (item, index) {        
        this.crud.form.detail_register.type = item.type
        this.crud.form.detail_register.amount = item.amount
        
        this.crud.form.detail_register.check.type = item.check.type
        this.crud.form.detail_register.check.bank = item.check.bank      
        this.crud.form.detail_register.check.number = item.check.number            
        this.crud.form.detail_register.check.observations = item.check.observations
        this.crud.form.detail_register.check.cuit = item.check.cuit      
        this.crud.form.detail_register.check.date_movement = item.check.date_movement        
        
        if (item.type=='cheque') {
          this.modal.check.active = true
        }

        this.crud.form.detail.splice(index, 1)
      },
      openCheck () {
        this.modal.check.active = true
        this.modal.check.title = "Datos del cheque"
      },
      checkOk () {
        if (  this.crud.form.detail_register.check.type == '' ||
              this.crud.form.detail_register.check.bank == '' ||
              this.crud.form.detail_register.check.number == '' ||
              this.crud.form.detail_register.check.cuit == '' ||
              this.crud.form.detail_register.check.date_movement == '') {
          return false
        } else {
          return true
        } 
      },
      clearCheck () {
        this.crud.form.detail_register.check.type = ''
        this.crud.form.detail_register.check.bank = ''      
        this.crud.form.detail_register.check.number = ''      
        this.crud.form.detail_register.check.observations = ''
        this.crud.form.detail_register.check.cuit = ''        
        this.crud.form.detail_register.check.date_movement = ''        
      },
      verifyBCRA() {                
        let routeData = this.$router.resolve({name: 'BCRAStaffQuery'});
        window.open(routeData.href, '_blank');        
      },      

      // ABM MAIN
      add() {
        this.crud.form.id = 0
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.customers_id = 0
        this.crud.form.customers = null
        this.crud.form.read = false
        this.crud.form.detail = []
        this.crud.form.vouchers = []

        this.modal.form.title = "Nuevo Reporte"
        this.modal.form.active = true
      },      
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.customers = item.customer

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') realizado al cliente ('+ this.crud.form.customers.name + ')?', {
          title: 'Borrar Reporte',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminar(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterReport()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la operación?', {
          title: 'Guardar Reporte',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();            


            this.crud.form.amount_items = this.totalPayment
            this.crud.form.amount_vouchers = this.totalVouchers

            var result = serviceAPI.agregar(this.crud.form);
            
            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterReport()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      },

      // LISTADO
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          this.arr.select.vouchersPending = []
          this.crud.form.type_operation = 'BLANCO'
          this.getVouchersPendingByCustomers('BLANCO')
          this.getBalancePendingByCustomers(object)
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0

          this.arr.select.vouchersPending = []
          this.crud.form.type_operation = 'BLANCO'
          this.getVouchersPendingByCustomers('BLANCO')
          this.getBalancePendingByCustomers(null)
        }
      }, 
      filterLoad() {
        this.filterLoadCustomers()
        this.filterLoadStaff()     
        this.filterLoadSellers()   
        this.filter.date_start = moment().subtract(7, 'days').format('YYYY-MM-DD') 
        this.filter.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage() 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.customers = []
          data.forEach(element => {            
            this.arr.filter.customers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadStaff() {
        var result = serviceAPI.obtenerStaff()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.staff = []
          data.forEach(element => {                            
            if(!element.seller) {             
              this.arr.filter.staff.push({ code: element.id, label: element.name })    
            }                    
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.sellers = []
          data.forEach(element => {                            
            this.arr.filter.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },      
      filterReport(){
        this.table.isBusy = true  
   
        var result = serviceAPI.filtrarReport(this.filter, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.prepareExport(data.data)

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }               

          this.table.isBusy = false
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });          
      }, 
      prepareExport(data) {
        data.forEach(element => {
          var staffID = 0            
          var staffName = ''
          var sellersID = 0
          var sellersName = ''
          var customersID = 0
          var customersCode = ''
          var customersName = ''

          if(element.staff_id) {
            staffID = element.staff.id
            staffName = element.staff.name
          } else {
            sellersID = element.seller.id
            sellersName = element.seller.name
          }

          if(element.customers_id) {
            customersID = element.customer.id
            customersCode = element.customer.code
            customersName = element.customer.name            
          }

          this.arr.export.push({
            id: element.id,
            date: element.date,
            sellersID: sellersID,
            sellersName: sellersName,
            staffID: staffID,
            staffName: staffName,
            customersID: customersID,
            customersCode: customersCode,
            customersName: customersName,
            amountPayment: element.amounts_items,            
            observations: element.observations,
            reference: '',
            type: '',
            amount: '',            
          })

          element.items.forEach(element1 => {
            this.arr.export.push({
              reference: 'Desgloce de Pago',              
              type: element1.type,
              amount: element1.amount
            })
          })

          element.vouchers.forEach(element2 => {
            this.arr.export.push({
              reference: 'Comprobantes',              
              type: element2.date + ' - ' + element2.vouchers,
              amount: element2.amount
            })
          })
          
        });
      },
      
      // SUBMENU
      checkRead(item) {
        let loader = this.$loading.show();     
        
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer
        this.crud.form.read = true
        
        var result = serviceAPI.editar(this.crud.form);
      
        result.then((response) => {          
          loader.hide()
          this.filterReport()
          this.$awn.success("Reporte marcado como Leido");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      checkNotRead(item) {
        let loader = this.$loading.show();     
        
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer
        this.crud.form.read = false
        
        var result = serviceAPI.editar(this.crud.form);
      
        result.then((response) => {          
          loader.hide()
          this.filterReport()
          this.$awn.success("Reporte marcado como No Leido");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_report_payment', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_report_payment')) {
          this.filter = JSON.parse(Storage.getValue('filter_report_payment'))
        }         
      },

      irDetail(item){
        this.saveFilterStorage()
        this.$router.push({ name: 'ReportsPaymentStaffDetailReport', params: {reportID: item.id} })
      },
      sendPrinterOrder(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
      }
    }    
  }
</script>
<style>
  .report-payment-crud-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }
  .report-payment-check-read-table {
    cursor: pointer;
  }    
  .report-payment-label {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #000;
  }  
  .report-payment-button-add {
    background: green;
    color: white;
    position: relative;
    min-width: 20px;
    padding: 5px 0px;
    margin-top: 30px;
    height: 30px;
    line-height: 20px;
    width: 30px;
  }
  .report-payment-button-add i {
    font-size: 15px;
  }  
  .report-payment-total-size {
    font-size: 20px;
  }
  .report-payment-diff-lack {
    color: red;
  }
  .report-payment-diff-surplus {
    color: green;
  }
  .report-payment-diff-just {
    color: blue;
  }
  .report-payment-popover-size {
    font-size: 14px;
  }
  .report-payment-popover-item-header {
    font-size: 14px !important;
    font-weight: 700 !important;   
    padding-right: 5px !important;      
  }  
  .report-payment-button-check {
    background: orange;
    color: white;
    position: relative;
    margin-left: 10px;
    min-width: 20px;
    padding: 5px 0px;
    margin-top: 30px;
    height: 30px;
    line-height: 20px;
    width: 30px;
  }
  .report-payment-button-check i {
    font-size: 15px;
  }  
  .report-payment-text-red {
    color: darkred;    
  }
  .report-payment-text-green {
    color: darkgreen;    
  }  
  .verify-credit-status {
    color: darkred !important;
    font-weight: bold;
    cursor: pointer;
  }
  .verify-credit-status:hover {
    text-decoration: underline !important; 
  }   
  .balance-pending {
    float: right;    
    font-weight: bold;    
  }
</style>